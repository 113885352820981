// --------------------------------------------------------------
// == config */
// --------------------------------------------------------------

// base
// --------------------------------------------------------------

$base-font: 14; // px value (without unit), will be converted in em
$line-height: 1.5;
$fontstack1: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack2: Georgia, Times, "Times New Roman", serif;

// responsive
// --------------------------------------------------------------

$xsmall: 480px;
$small: 768px;
$medium: 960px;
$large: 1280px;

// default gutter
// --------------------------------------------------------------

$default-gutter: 1rem;

// Focus ring
// --------------------------------------------------------------

$focus-ring: 0 0 0 .3rem rgba($color-info, .5);
