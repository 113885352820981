/**
* Button Module
* namespace : .btn
*/

/* ----------------------------------------------------------- */
/* == configuration */
/* ----------------------------------------------------------- */


$btn-primary: $color-primary;
$btn-secondary: $color-secondary;
$btn-success: #1cb841;
$btn-danger: #c53a3a;
$btn-hover: 5%;
$btn-radius: .5rem;


/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

.btn {
  display: inline-block;
  padding: .8em 1.6em;
  border: none;
  border-radius: $btn-radius;
  background-color: $btn-primary;
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  text-decoration: none !important;
  line-height: normal;
  cursor: pointer;
  transition: background-color .4s;
}

.btn:active,
.btn:focus {
  outline: none;
  box-shadow: $focus-ring;
}

.btn:disabled {
  opacity: .5;
  cursor: default;
}

/* colors
-------------------------------------------------------------- */

.btn--primary,
.btn--primary:link,
.btn--primary:visited {
  background-color: $btn-primary;
  color: #fff;
}

.btn--primary:hover,
.btn--primary:active,
.btn--primary:focus {
  background-color: darken($btn-primary, $btn-hover);
}

.btn--secondary,
.btn--secondary:link,
.btn--secondary:visited {
  background-color: $btn-secondary;
  color: #fff;
}

.btn--secondary:hover,
.btn--secondary:active,
.btn--secondary:focus {
  background-color: darken($btn-secondary, $btn-hover);
}

.btn--danger,
.btn--danger:link,
.btn--danger:visited {
  background-color: $btn-danger;
  color: #fff;
}


.btn--danger:hover,
.btn--danger:active,
.btn--danger:focus {
  background-color: darken($btn-danger, $btn-hover);
}

/* size
-------------------------------------------------------------- */

.btn--big {
  font-size: $base-font + 4;
}
.btn--small {
  font-size: $base-font - 4;
}
