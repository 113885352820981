// --------------------------------------------------------------
// == config */
// --------------------------------------------------------------

@import "_colors";
@import "_config";

// --------------------------------------------------------------
// == core */
// --------------------------------------------------------------

@import "1-core/_00-mixins";
@import "1-core/_01-reset";
@import "1-core/_02-typography";
@import "1-core/_03-helpers";
@import "1-core/_04-layout";
@import "1-core/_05-flexgrid";
@import "1-core/_06-rwd";
@import "1-core/_debug";

// --------------------------------------------------------------
// == helpers */
// --------------------------------------------------------------

@import "2-helpers/_alignment";
@import "2-helpers/_colors";
@import "2-helpers/_spacing";
@import "2-helpers/_text";
@import "2-helpers/_width";

// --------------------------------------------------------------
// == components */
// --------------------------------------------------------------

@import "3-components/_forms";
@import "3-components/_switch";
@import "3-components/_radiobox";
@import "3-components/_buttons";
@import "3-components/_pagination";
@import "3-components/_tables";
@import "3-components/_tooltips";
@import "3-components/_notifications";


// --------------------------------------------------------------
// == base */
// --------------------------------------------------------------

@import "4-base/_01-fonts";
@import "4-base/_02-icons";
@import "4-base/_03-grids";
@import "4-base/_04-main";
@import "4-base/_05-layout";
@import "4-base/_06-header";
@import "4-base/_07-navigation";
@import "4-base/_08-content";
@import "4-base/_09-footer";


// --------------------------------------------------------------
// == modules */
// --------------------------------------------------------------

@import "5-modules/placeholder";
@import "5-modules/player-big";
@import "5-modules/player-layout";
@import "5-modules/player-sticky";
@import "5-modules/player-cover";
@import "5-modules/player-progress";
@import "5-modules/player-track";
@import "5-modules/volume";
@import "5-modules/controls";
@import "5-modules/queue";
@import "5-modules/track";
@import "5-modules/context-menu";
@import "5-modules/modal";
@import "5-modules/modal-share";
@import "5-modules/tags";


// --------------------------------------------------------------
// == pages */
// --------------------------------------------------------------

@import "6-pages/context";
@import "6-pages/settings";


// --------------------------------------------------------------
// == vendors */
// --------------------------------------------------------------

// Tips: load vendor from your package manager and override CSS here (e.g. o-vendor-name.scss)
// In this context, "o" means "override" and you can use it as a convention.

// @import "7-vendors/o-vendor.scss";


// --------------------------------------------------------------
// == rwd */
// --------------------------------------------------------------

@import "8-rwd/_rwd-large";
@import "8-rwd/_rwd-medium";
@import "8-rwd/_rwd-small";
@import "8-rwd/_rwd-xsmall";
@import "8-rwd/_rwd";

div.app {
    background-color: $color-info !important;
    padding: 0 4rem;
}