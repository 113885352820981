// --------------------------------------------------------------
// == colors */
// --------------------------------------------------------------

// palette
// --------------------------------------------------------------

$color-primary: #ff7510;
$color-secondary: #ff7510;
$color-tertiary: #bd4f00;
$color-info: #222;
$color-bg: #333;

// texts
// --------------------------------------------------------------

$color-light-text: #ddd;
$color-dark-text: $color-primary;

// links
// --------------------------------------------------------------

$color-link: $color-primary;

// greys
// --------------------------------------------------------------

$color-grey-1: #ddd;
$color-grey-2: #fff;
$color-grey-3: #000 ;
