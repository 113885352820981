/* ----------------------------------------------------------- */
/* == rwd -> xsmall */
/* ----------------------------------------------------------- */

@media (max-width: $xsmall) {
  /* Player
    -------------------------------------------------------------- */

  .player {
    display: flex;
    flex-direction: column;
  }

  .playerMini {
    align-self: center;
    margin-bottom: 2rem;
    width: 16rem;
  }

  .track--active::before {
    display: none;
  }

  .playerSticky .controls__volume,
  .playerSticky .playerProgress,
  .playerSticky__referer {
    display: none;
  }

  .playerSticky__cover {
    margin-right: 1rem;
  }

  .playerSticky .controls {
    margin-right: 1rem;
  }

  .playerSticky .controls__prevnext {
    display: none;
  }

  .playerSticky__infos {
    margin-right: 0;
  }
}
