/**
 * Form Module
 * Namespace : .form
 */


/* ----------------------------------------------------------- */
/* == configuration */
/* ----------------------------------------------------------- */

$input-border-radius: .3rem;
$input-border: .1rem solid #000000;
$input-background-color: #444444;
$input-hover: 5%;
$input-placeholder-color: #ffffff;
$input-error: #ff0000;
$input-required: #990000;


/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

/* placeholders
-------------------------------------------------------------- */

input::-webkit-input-placeholder {
  color: $input-placeholder-color;
}
input:-moz-placeholder {
  color: $input-placeholder-color;
}
textarea::-webkit-input-placeholder {
  color: $input-placeholder-color;
}
textarea:-moz-placeholder {
  color: $input-placeholder-color;
}

/* block form
-------------------------------------------------------------- */

label,
.label {
  display: block;
  margin-top: 1.3em;
  margin-bottom: .8em;
  vertical-align: middle;
  cursor: pointer;
}

/* inline form
-------------------------------------------------------------- */

.f-inline label,
.f-inline .label {
  display: inline-block;
  margin: 0 1rem;
}

.f-inline {
  display: inline-block;
}

.f-inline input,
.f-inline .btn {
  height: 4rem;
}

.f-inline .btn {
  vertical-align: middle;
}


/* basics style
-------------------------------------------------------------- */

fieldset {
  margin: 2.5rem 0;
  padding: 1em;
  border: .1rem solid #d8d8d8;
}

input,
select,
label,
.label {
  vertical-align: middle;
}

select {
  margin: 0;
  border: .1rem solid #ccc;
  background-color: #fff;
}

textarea {
  padding: .4em;
  min-width: 32rem;
  min-height: 8em;
  vertical-align: top;
  resize: vertical;
}

input {
  padding: 1rem 2rem;
  max-width: 32rem;
  width: 100%;
  border: $input-border;
  border-radius: $input-border-radius;
  background: $input-background-color;
  color: $color-secondary;
  line-height: 1.3em;
}

input.error {
  border-color: #da0000;
}

input[readonly] {
  background: #f1f1f1;
}

textarea:active,
textarea:focus,
select:active,
select:focus,
input:active,
input:focus {
  outline: none;
  box-shadow: $focus-ring;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  display: inline-block;
  margin-right: 1rem;
  vertical-align: baseline;
}

input[type="radio"],
input[type="checkbox"] {
  padding: 0;
  width: inherit;
}

input[type="checkbox"] {
  appearance: none;
  margin-right: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  border: .1rem solid $color-grey-2;
  border-radius: .3rem;
  background: no-repeat url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.452.833L3.75 5.653 1.548 3.565 0 5.113 3.75 8.75 10 2.381 8.452.833z' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-size: 0 0;
  vertical-align: top;
  transition: .15s all, .2s background-size cubic-bezier(.64, 1.93, .55, 1.94);
}

input[type="checkbox"]:checked {
  border-color: $color-primary;
  background-color: $color-primary;
  background-size: 1rem .8rem;
}

/* autogrid
-------------------------------------------------------------- */

[class^="f-grid"] {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

[class^="f-grid"] > * {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin-left: 2rem;
}

[class^="f-grid"] > *:first-child {
  margin-left: 0;
}

[class^="f-grid"] input {
  max-width: 100%;
}

/* size
-------------------------------------------------------------- */

.f-size-1 {
  max-width: 10rem;
}

.f-size-2 {
  max-width: 20rem;
}

.f-size-3 {
  max-width: 30rem;
}

.f-size-4 {
  max-width: 40rem;
}

.f-size-5 {
  max-width: 50rem;
}

.f-size-full {
  max-width: 100%;
}

/* errors
-------------------------------------------------------------- */

.f-required {
  color: $input-error;
  font-weight: bold;
}

.f-error input {
  margin-bottom: .8rem;
  border: .1rem solid $input-error;
}

.f-error-message {
  color: $input-error;
}
