html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}

body {
  margin: 0;
}

img, table, td, blockquote, code, pre, textarea, input, video, svg {
  max-width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

button, input, select, textarea {
  font: inherit;
}

html {
  font-size: .625em;
}

body {
  font-size: 1.4em;
  line-height: 1.5;
}

p, ul, ol, dl, blockquote, pre, td, th, label, textarea, caption {
  margin: 0 0 1.5em;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.mod {
  overflow: hidden;
}

.clear, .line, .row {
  clear: both;
}

.clearfix:after, .line:after, .mod:after {
  clear: both;
  content: "";
  display: table;
}

.inbl {
  display: inline-block;
}

.grid {
  flex-flow: wrap;
  align-items: stretch;
  display: flex;
}

.grid > * {
  flex: none;
  display: block;
}

[class*="grid-top"] {
  align-items: flex-start;
}

[class*="grid-bottom"] {
  align-items: flex-end;
}

[class*="grid-center"] {
  align-items: center;
}

[class*="grid-"].grid-noMargin > * {
  margin-bottom: 0;
}

.responsive-iframe {
  height: 0;
  padding-top: 3rem;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.responsive-iframe iframe, .responsive-iframe object, .responsive-iframe embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden {
  display: none;
}

@media (min-width: 1281px) {
  [class*="-l+"][class*="hidden-"]:not([class*="up"]) {
    display: none !important;
  }
}

@media (min-width: 961px) and (max-width: 1280px) {
  [class*="-l"][class*="hidden-"]:not([class*="up"]) {
    display: none !important;
  }
}

@media (min-width: 769px) and (max-width: 960px) {
  [class*="-m"][class*="hidden-"]:not([class*="up"]) {
    display: none !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  [class*="-s"][class*="hidden-"]:not([class*="up"]) {
    display: none !important;
  }
}

@media (max-width: 480px) {
  [class*="-xs"][class*="hidden-"]:not([class*="up"]) {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-s-up {
    display: none !important;
  }
}

@media (min-width: 480px) {
  .hidden-xs-up {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .mod {
    float: none !important;
    width: auto !important;
    display: block !important;
  }
}

.debug-rythm {
  background: linear-gradient(#ba9b9a .1em, rgba(0, 0, 0, 0) .1em) 0 0 / 100% 1.5em !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.txtright {
  text-align: right;
}

.txtcenter {
  text-align: center;
}

.txtleft {
  text-align: left;
}

.vtop {
  vertical-align: top;
}

.vbottom {
  vertical-align: bottom;
}

.vmiddle {
  vertical-align: middle;
}

.color-primary, .color-secondary {
  color: #ff7510;
}

.ma0 {
  margin: 0;
}

.pa0 {
  padding: 0;
}

.ma1 {
  margin: 1rem;
}

.ma2 {
  margin: 2rem;
}

.ma3 {
  margin: 3rem;
}

.pa1 {
  padding: 1rem;
}

.pa2 {
  padding: 2rem;
}

.pa3 {
  padding: 3rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.txtsmaller {
  margin-bottom: 2.1em;
  font-size: 1rem;
  line-height: 2.1;
}

.txtsmall {
  margin-bottom: 1.75em;
  font-size: 1.2rem;
  line-height: 1.75;
}

.txtbig {
  margin-bottom: 1.3125em;
  font-size: 1.6rem;
  line-height: 1.3125;
}

.txtbigger {
  margin-bottom: 1.16667em;
  font-size: 1.8rem;
  line-height: 1.16667;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w33 {
  width: 33.3333%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w66 {
  width: 66.6666%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

input::-webkit-input-placeholder {
  color: #fff;
}

input:-moz-placeholder {
  color: #fff;
}

textarea::-webkit-input-placeholder {
  color: #fff;
}

textarea:-moz-placeholder {
  color: #fff;
}

label, .label {
  vertical-align: middle;
  cursor: pointer;
  margin-top: 1.3em;
  margin-bottom: .8em;
  display: block;
}

.f-inline label, .f-inline .label {
  margin: 0 1rem;
  display: inline-block;
}

.f-inline {
  display: inline-block;
}

.f-inline input, .f-inline .btn {
  height: 4rem;
}

.f-inline .btn {
  vertical-align: middle;
}

fieldset {
  border: .1rem solid #d8d8d8;
  margin: 2.5rem 0;
  padding: 1em;
}

input, select, label, .label {
  vertical-align: middle;
}

select {
  background-color: #fff;
  border: .1rem solid #ccc;
  margin: 0;
}

textarea {
  vertical-align: top;
  resize: vertical;
  min-width: 32rem;
  min-height: 8em;
  padding: .4em;
}

input {
  color: #ff7510;
  background: #444;
  border: .1rem solid #000;
  border-radius: .3rem;
  width: 100%;
  max-width: 32rem;
  padding: 1rem 2rem;
  line-height: 1.3em;
}

input.error {
  border-color: #da0000;
}

input[readonly] {
  background: #f1f1f1;
}

textarea:active, textarea:focus, select:active, select:focus, input:active, input:focus {
  outline: none;
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

input[type="radio"] + label, input[type="checkbox"] + label {
  vertical-align: baseline;
  margin-right: 1rem;
  display: inline-block;
}

input[type="radio"] {
  width: inherit;
  padding: 0;
}

input[type="checkbox"] {
  width: inherit;
  padding: 0;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: top;
  background: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.452.833L3.75 5.653 1.548 3.565 0 5.113 3.75 8.75 10 2.381 8.452.833z' fill='%23fff'/%3E%3C/svg%3E%0A") center / 0 0 no-repeat;
  border: .1rem solid #fff;
  border-radius: .3rem;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  transition: all .15s, background-size .2s cubic-bezier(.64, 1.93, .55, 1.94);
}

input[type="checkbox"]:checked {
  background-color: #ff7510;
  background-size: 1rem .8rem;
  border-color: #ff7510;
}

[class^="f-grid"] {
  flex-wrap: nowrap;
  align-items: flex-end;
  display: flex;
}

[class^="f-grid"] > * {
  flex-direction: column;
  flex: 1 0 0;
  margin-left: 2rem;
  display: flex;
}

[class^="f-grid"] > :first-child {
  margin-left: 0;
}

[class^="f-grid"] input {
  max-width: 100%;
}

.f-size-1 {
  max-width: 10rem;
}

.f-size-2 {
  max-width: 20rem;
}

.f-size-3 {
  max-width: 30rem;
}

.f-size-4 {
  max-width: 40rem;
}

.f-size-5 {
  max-width: 50rem;
}

.f-size-full {
  max-width: 100%;
}

.f-required {
  color: red;
  font-weight: bold;
}

.f-error input {
  border: .1rem solid red;
  margin-bottom: .8rem;
}

.f-error-message {
  color: red;
}

.switch[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: 42rem;
  outline: none;
  width: 4rem;
  height: 2rem;
  margin-top: -.05em;
  transition: all .2s;
  position: relative;
  box-shadow: inset -2rem 0 0 .1rem rgba(192, 192, 192, .5);
}

.switch[type="checkbox"]::-ms-check {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.switch[type="checkbox"]:checked {
  border-color: #ff7510;
  box-shadow: inset 2rem 0 0 .1rem rgba(255, 117, 16, .7);
}

.switch[type="checkbox"]:active {
  box-shadow: inset -2rem 0 0 .1rem rgba(192, 192, 192, .5), 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.switch[type="checkbox"]:focus {
  box-shadow: inset -2rem 0 0 .1rem rgba(192, 192, 192, .5), 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.switch[type="checkbox"]:checked:active {
  box-shadow: inset 2rem 0 0 .1rem rgba(255, 117, 16, .7), 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.switch[type="checkbox"]:checked:focus {
  box-shadow: inset 2rem 0 0 .1rem rgba(255, 117, 16, .7), 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.radiobox {
  background: #444;
  border: .1rem solid #000;
  border-radius: .3rem;
  width: 100%;
  max-width: 32rem;
  padding: .2rem;
  display: inline-flex;
}

.radiobox--full {
  width: 100%;
  max-width: 100%;
}

.radiobox input {
  position: absolute;
  left: -9999px;
}

.radiobox input + label {
  text-align: center;
  white-space: nowrap;
  border-radius: .3rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 4rem;
  margin: 0;
  padding: .5rem 1rem;
  font-weight: normal;
  line-height: 1.1em;
  transition: all .1s ease-in;
  display: flex;
}

.radiobox input:checked + label {
  color: #fff;
  background-color: #ff7510;
}

.radiobox input:focus + label, .radiobox:active {
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.radiobox:focus-within {
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.radiobox:focus-within input:focus + label {
  box-shadow: none;
}

.btn {
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: #ff7510;
  border: none;
  border-radius: .5rem;
  padding: .8em 1.6em;
  line-height: normal;
  transition: background-color .4s;
  display: inline-block;
  text-decoration: none !important;
}

.btn:active, .btn:focus {
  outline: none;
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.btn:disabled {
  opacity: .5;
  cursor: default;
}

.btn--primary, .btn--primary:link, .btn--primary:visited {
  color: #fff;
  background-color: #ff7510;
}

.btn--primary:hover, .btn--primary:active, .btn--primary:focus {
  background-color: #f66800;
}

.btn--secondary, .btn--secondary:link, .btn--secondary:visited {
  color: #fff;
  background-color: #ff7510;
}

.btn--secondary:hover, .btn--secondary:active, .btn--secondary:focus {
  background-color: #f66800;
}

.btn--danger, .btn--danger:link, .btn--danger:visited {
  color: #fff;
  background-color: #c53a3a;
}

.btn--danger:hover, .btn--danger:active, .btn--danger:focus {
  background-color: #b13434;
}

.btn--big {
  font-size: 18px;
}

.btn--small {
  font-size: 10px;
}

.pagination {
  border-radius: .4rem;
  margin: 2rem 0;
  display: inline-block;
}

.pagination, .pagination li {
  margin: 0;
  padding: 0;
}

.pagination li {
  margin: 0 .3rem 1em;
  list-style-type: none;
  display: inline-block;
}

.pagination li a, .pagination li > span {
  border: .1rem solid gray;
  border-radius: .2rem;
  padding: .3em .6em;
}

.pagination li a:hover {
  color: #ff7510;
}

.pagination li.current a {
  color: #fff;
  background-color: #ff7510;
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border: .1rem solid #cbcbcb;
  width: 100%;
  max-width: 100%;
}

.table caption {
  color: #000;
  text-align: center;
  padding: 1em 0;
  font: italic 85% / 1 arial, sans-serif;
}

.table td, .table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: inherit;
  border-width: 0 0 0 .1rem;
  border-left-style: solid;
  border-left-color: #cbcbcb;
  margin: 0;
  padding: .5em 1em;
  overflow: hidden;
}

.table td:first-child, .table th:first-child {
  border-left-width: 0;
}

.table thead {
  color: #000;
  vertical-align: bottom;
  text-align: left;
  background: #e0e0e0;
}

.tooltip {
  cursor: help;
  position: relative;
}

.tooltip:after {
  font-family: helvetica, arial, sans-serif;
}

.tooltip:hover:after {
  color: #fff;
  content: attr(data-tooltip);
  white-space: nowrap;
  background: rgba(0, 0, 0, .8);
  border-radius: .5em;
  padding: .5em 1em;
  position: absolute;
  bottom: 1.35em;
  left: 1em;
}

.tooltip:hover:before {
  content: "";
  border: .4em solid rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, .8);
  border-bottom: 0 solid rgba(0, 0, 0, .8);
  display: block;
  position: absolute;
  bottom: 1em;
  left: 2em;
}

.notif {
  border-radius: .3rem;
  margin-top: .5rem;
  padding: .5em 1em;
  font-size: 1.3rem;
}

.notif--info {
  color: #183fc8;
  background-color: rgba(0, 100, 218, .15);
}

.notif--success {
  color: #0f4014;
  background-color: rgba(0, 218, 100, .15);
}

.notif--warning {
  color: #402c0f;
  background-color: rgba(218, 105, 0, .15);
}

.notif--error {
  color: #9e0000;
  background-color: rgba(218, 0, 0, .15);
}

@font-face {
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  font-family: Roboto;
  src: local(Roboto Regular), local(Roboto), url("roboto-regular.5b4effbe.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  font-family: Roboto;
  src: local(Roboto Medium), local(Roboto), url("roboto-medium.349cbcde.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-weight: bold;
  font-style: normal;
  font-family: Roboto;
  src: local(Roboto Bold), local(Roboto), url("roboto-bold.b6127d6c.woff2") format("woff2");
}

.grid-1 {
  margin-bottom: -2rem;
  margin-left: 0;
}

.grid-1 > :nth-child(n) {
  width: 100%;
  margin-bottom: 2rem;
  margin-left: 0;
}

.grid-2 {
  margin-bottom: -2rem;
  margin-left: -2rem;
}

.grid-2 > :nth-child(n) {
  width: calc(50% - 2rem - .01px);
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.grid-3 {
  margin-bottom: -2rem;
  margin-left: -2rem;
}

.grid-3 > :nth-child(n) {
  width: calc(33.3333% - 2rem - .01px);
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.grid-4 {
  margin-bottom: -2rem;
  margin-left: -2rem;
}

.grid-4 > :nth-child(n) {
  width: calc(25% - 2rem - .01px);
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.grid-2-noGutter {
  margin-bottom: 0;
}

.grid-2-noGutter > :nth-child(n) {
  width: calc(50% - .01px);
  margin-bottom: 0;
}

.grid-1-3 > * {
  margin-bottom: 2rem;
}

.grid-1-3 > :nth-child(odd) {
  width: calc(25% - 1rem - .01px);
}

.grid-1-3 > :nth-child(2n) {
  width: calc(75% - 1rem - .01px);
  margin-left: 2rem;
}

@media (max-width: 480px) {
  .grid-1-xs {
    margin-bottom: -1rem;
    margin-left: 0;
  }

  .grid-1-xs > :nth-child(n) {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .grid-2-xs {
    margin-bottom: -1rem;
    margin-left: -1rem;
  }

  .grid-2-xs > :nth-child(n) {
    width: calc(50% - 1rem - .01px);
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

body {
  color: #ff7510;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.container {
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

h1 {
  margin-bottom: .42em;
  font-size: 5rem;
  line-height: 1.26;
}

h2 {
  margin-bottom: .954545em;
  font-size: 2.2rem;
  line-height: 1.90909;
}

h3 {
  margin-bottom: 1.16667em;
  font-size: 1.8rem;
  line-height: 1.16667;
}

h4 {
  margin-bottom: 1.3125em;
  font-size: 1.6rem;
  line-height: 1.3125;
}

a, a:visited {
  color: #ff7510;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active, a:focus, button:active, button:focus {
  outline: none;
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

button {
  cursor: pointer;
}

ul {
  padding-left: 2rem;
  list-style-type: disc;
}

hr {
  border: 0;
  border-top: .1rem solid #ccc;
  height: .1rem;
  margin: 1em 0;
  padding: 0;
  display: block;
}

.header {
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4.7rem;
  padding-bottom: 5rem;
  display: flex;
}

.header__title {
  text-align: center;
  margin: 0;
  font-size: 2rem;
}

.header__titleLink {
  padding: 1rem;
  display: inline-block;
}

.header__side {
  margin-bottom: -1rem;
  padding-bottom: .7rem;
}

.header__side:first-child {
  margin-left: -1rem;
}

.header__side:last-child {
  margin-right: -1rem;
}

.header__sideBtn, .header__sideBtn:visited {
  color: #ff7510;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: .2rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.14em;
}

.header__sideBtn:hover, .header__sideBtn:active {
  text-decoration: underline;
}

.footer {
  color: rgba(255, 117, 16, .4);
  text-align: center;
  margin-top: 6rem;
  padding-top: 2.4rem;
  padding-bottom: 4rem;
  font-size: 1.3rem;
  position: relative;
}

.footer:before {
  content: "";
  background-color: rgba(255, 117, 16, .15);
  width: 2rem;
  height: .2rem;
  position: absolute;
  top: 0;
  left: calc(50% - 1rem);
}

.footer a, .footer a:hover, .footer a:visited {
  color: rgba(255, 117, 16, .4);
  text-decoration: underline;
}

.footer__illu {
  margin-bottom: 2rem;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

.placeholder {
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0);
  background: linear-gradient(to right, #eee 8%, #dfdfdf 18%, #eee 33%) 0 0 / 100rem 10.4rem;
  min-height: 1em;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  position: relative;
}

.playerBig__player {
  background-color: #f2f2f2;
  border-radius: .4rem .4rem 0 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.playerBig__player > iframe, .playerBig__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.playerBig__reduce, .playerBig__reduce:visited {
  z-index: 1;
  color: #fff;
  opacity: .6;
  background-color: #bd4f00;
  border: none;
  border-radius: .2rem;
  align-items: center;
  padding: .6rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.27em;
  transition: opacity .15s;
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.playerBig__reduce.active, .playerBig__reduce:hover, .playerBig__reduce:active, .playerBig__reduce:focus {
  opacity: 1;
}

.playerBig__reduce svg {
  margin-left: .6rem;
}

.player {
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 5rem;
  display: grid;
}

.playerBig__player, .playerMini {
  grid-area: 1 / 1 / 4 / 2;
}

.playerTrack {
  grid-area: 1 / 2 / 2 / 3;
}

.playerProgress {
  grid-area: 2 / 2 / 3 / 3;
}

.controls {
  grid-area: 3 / 2 / 4 / 3;
}

.playerBig {
  grid-row-gap: 0;
  grid-column-gap: 0;
  grid-template-columns: 1fr;
  margin-bottom: 6rem;
  display: grid;
}

.playerBig .playerBig__player, .playerBig .playerMini {
  grid-area: 1 / 1 / 2 / 2;
}

.playerBig .playerMini {
  display: none;
}

.playerBig .playerTrack {
  grid-area: 3 / 1 / 4 / 2;
}

.playerBig .playerProgress {
  grid-area: 2 / 1 / 3 / 2;
}

.playerBig .controls {
  grid-area: 4 / 1 / 5 / 2;
}

.playerSticky {
  z-index: 2;
  background-color: #333;
  border-bottom: .1rem solid rgba(255, 117, 16, .15);
  width: 100%;
  padding: .7rem 0;
  transition: all .15s;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.playerSticky.hidden {
  display: block;
  -webkit-transform: translateY(-103%);
  transform: translateY(-103%);
}

.playerSticky > .container {
  display: flex;
  position: relative;
}

.playerSticky__cover {
  background: rgba(255, 117, 16, .15);
  border-radius: .3rem;
  flex-shrink: 0;
  width: 4.6rem;
  height: 4.6rem;
  margin-right: 2rem;
  position: relative;
  overflow: hidden;
}

.playerSticky__coverImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.playerSticky .controls {
  flex-shrink: 0;
  margin-right: 3rem;
}

.playerSticky .controls__menu {
  display: none;
}

.playerSticky .controls__volume {
  position: absolute;
  right: .7rem;
}

.playerSticky .controls__prevnext {
  padding: .5rem;
}

.playerSticky .controls__playpause {
  margin: 0 .5rem;
}

.playerSticky .controls__playpause svg {
  width: 3rem;
  height: 3rem;
}

.playerSticky__infos {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-right: 7.3rem;
  display: flex;
}

.playerSticky__track {
  color: #ff7510;
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2em;
}

.playerSticky__referer {
  font-size: .9rem;
  line-height: 1.2em;
}

.playerSticky .playerProgress {
  margin: .5rem 0;
}

.playerSticky .playerProgress__timecode {
  display: none;
}

.playerSticky .playerProgress__progressBg, .playerSticky .playerProgress__progressBgProgress, .playerSticky .playerProgress__progressInput {
  height: .2rem;
}

.playerSticky .playerProgress__progressInput::-moz-range-progress {
  height: .2rem;
}

.playerSticky .playerProgress__progressInput::-ms-fill-lower {
  height: .2rem;
}

.playerCover {
  background-color: #f2f2f2;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.playerCover:before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.playerCover__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.playerCover__expand, .playerCover__expand:visited {
  color: rgba(255, 255, 255, .6);
  background: rgba(255, 117, 16, .2);
  border: none;
  border-radius: .3rem;
  width: 2.4rem;
  height: 2.4rem;
  padding: .5rem;
  font-size: 0;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.playerCover:hover .playerCover__expand, .playerCover:active .playerCover__expand, .playerCover:focus .playerCover__expand {
  color: #fff;
  background-color: rgba(255, 117, 16, .6);
}

.playerBig .playerProgress {
  margin-bottom: 3rem;
}

.playerProgress__progress {
  position: relative;
}

.playerProgress__progressBg {
  background-color: #eee;
  border-radius: .5rem;
  width: 100%;
  height: .5rem;
  position: absolute;
}

.playerBig .playerProgress__progressBg {
  border-radius: 0 0 .2rem .2rem;
}

.playerProgress__progressBgProgress {
  background-color: #ff7510;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  width: 0;
  height: .5rem;
  position: absolute;
}

.playerBig .playerProgress__progressBgProgress {
  border-top-left-radius: 0;
}

.playerProgress__progressInput {
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
  max-width: inherit;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  width: 100%;
  height: .5rem;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.playerBig .playerProgress__progressInput {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.playerProgress__progressInput::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background: #fff;
  border: none;
  border-radius: 5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -.1rem;
  transition: opacity .15s;
  box-shadow: -.1rem .2rem .2rem rgba(99, 87, 118, .4);
}

.playerProgress__progressInput::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background: #fff;
  border: none;
  border-radius: 5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -.1rem;
  transition: opacity .15s;
  box-shadow: -.1rem .2rem .2rem rgba(99, 87, 118, .4);
}

.playerProgress__progressInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background: #fff;
  border: none;
  border-radius: 5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -.1rem;
  transition: opacity .15s;
  box-shadow: -.1rem .2rem .2rem rgba(99, 87, 118, .4);
}

.playerProgress__progressInput:hover::-moz-range-thumb {
  opacity: 1;
}

.playerProgress__progressInput:hover::-ms-thumb {
  opacity: 1;
}

.playerProgress__progressInput:hover::-webkit-slider-thumb {
  opacity: 1;
}

.playerProgress__progressInput::-moz-range-progress {
  background-color: #ff7510;
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
  height: .5rem;
}

.playerProgress__progressInput::-ms-fill-lower {
  background-color: #ff7510;
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
  height: .5rem;
}

.playerBig .playerProgress__progressInput::-moz-range-progress {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.playerBig .playerProgress__progressInput::-ms-fill-lower {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.playerProgress__timecode {
  color: #ddd;
  justify-content: space-between;
  margin: 1rem 0;
  font-size: 1.2rem;
  display: flex;
}

.playerProgress__timecodeItem {
  display: block;
}

.playerTrack {
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: .8rem;
  display: flex;
}

.playerBig .playerTrack {
  margin-bottom: 3rem;
}

.playerTrack__infos {
  flex-grow: 1;
  min-width: 0;
}

.playerTrack__name {
  display: -webkit-box;
  display: box;
  color: #ff7510;
  text-overflow: ellipsis;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  margin-bottom: .8rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.16em;
  overflow: hidden;
}

.playerTrack__referer {
  color: #ddd;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 1.4rem;
  overflow: hidden;
}

.playerTrack__username {
  text-transform: capitalize;
}

.playerTrack__fav {
  color: rgba(255, 117, 16, .8);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: -.9rem;
  margin-left: 1rem;
  margin-right: -1rem;
  padding: 1rem;
  font-size: 0;
}

.playerTrack__fav:hover, .playerTrack__fav:active, .playerTrack__fav:focus {
  color: #ff7510;
}

.playerTrack__fav svg .filled {
  display: none;
}

.volume {
  margin: 0;
  position: relative;
}

.playerBig .volume {
  -webkit-transform-origin: 0;
  transform-origin: 0;
  -webkit-transform: scale(1.11);
  transform: scale(1.11);
}

.volume__bg, .volume__progress {
  -webkit-clip-path: url("#volume");
  clip-path: url("#volume");
  width: calc(100% - .2rem);
  height: calc(100% - .4rem);
  margin: .2rem .1rem;
  position: absolute;
  top: 0;
  left: 0;
}

.volume__bg {
  background: rgba(99, 87, 118, .4);
}

.volume__progress {
  background-color: #ff7510;
}

.volume__input {
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: .1rem;
  width: 4rem;
  padding: 0;
  position: relative;
}

.volume__input::-moz-range-track {
  cursor: pointer;
  animate: .2s;
  -webkit-clip-path: url("#volume");
  clip-path: url("#volume");
  background: none;
  border-radius: 0;
  width: 100%;
  height: 17px;
  box-shadow: 0 0 #000, 0 0 #0d0d0d;
}

.volume__input::-ms-track {
  cursor: pointer;
  animate: .2s;
  -webkit-clip-path: url("#volume");
  clip-path: url("#volume");
  background: none;
  border-radius: 0;
  width: 100%;
  height: 17px;
  box-shadow: 0 0 #000, 0 0 #0d0d0d;
}

.volume__input::-webkit-slider-runnable-track {
  cursor: pointer;
  animate: .2s;
  -webkit-clip-path: url("#volume");
  clip-path: url("#volume");
  background: none;
  border-radius: 0;
  width: 100%;
  height: 17px;
  box-shadow: 0 0 #000, 0 0 #0d0d0d;
}

.volume__input::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #fff;
  border: 0 solid #000;
  border-radius: 2px;
  width: 6px;
  height: 19px;
  position: relative;
  -webkit-transform: translateY(-.1rem);
  transform: translateY(-.1rem);
  box-shadow: -1px 2px 4px rgba(99, 87, 118, .6);
}

.volume__input::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #fff;
  border: 0 solid #000;
  border-radius: 2px;
  width: 6px;
  height: 19px;
  position: relative;
  -webkit-transform: translateY(-.1rem);
  transform: translateY(-.1rem);
  box-shadow: -1px 2px 4px rgba(99, 87, 118, .6);
}

.volume__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #fff;
  border: 0 solid #000;
  border-radius: 2px;
  width: 6px;
  height: 19px;
  position: relative;
  -webkit-transform: translateY(-.1rem);
  transform: translateY(-.1rem);
  box-shadow: -1px 2px 4px rgba(99, 87, 118, .6);
}

.controls {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.controls__volume {
  flex-shrink: 0;
  align-items: center;
  margin-right: 1rem;
  padding-top: .1rem;
  display: flex;
}

.controls__controls {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.controls__prevnext {
  color: #ff7510;
  background: none;
  border: none;
  border-radius: .2rem;
  padding: 1rem;
  font-size: 0;
}

.controls__prevnext.disabled {
  color: rgba(255, 117, 16, .4);
}

.controls__prevnext svg {
  width: 1.8rem;
  height: 2rem;
}

.playerBig .controls__prevnext svg {
  width: 2.1rem;
  height: 2.2rem;
}

.controls__playpause {
  color: #ff7510;
  background: none;
  border: none;
  border-radius: 4.2rem;
  margin: 0 2rem;
  padding: 0;
  font-size: 0;
}

.controls__playpause svg {
  width: 4.6rem;
  height: 4.6rem;
}

.playerBig .controls__playpause {
  margin: 0 3rem;
}

.playerBig .controls__playpause svg {
  width: 5.6rem;
  height: 5.6rem;
}

.controls__playpause.disabled {
  color: rgba(255, 117, 16, .4);
}

.controls__menu {
  margin-right: -1rem;
}

.controls__menuBtn {
  color: #ff7510;
  background: none;
  border: none;
  border-radius: .2rem;
  padding: 1rem;
  font-size: 0;
}

.controls__menuBtn.disabled {
  color: rgba(255, 117, 16, .4);
}

.controls__menuBtn svg {
  width: 2.5rem;
  height: .5rem;
}

.playerBig .controls__menuBtn svg {
  width: 3.2rem;
  height: .6rem;
}

.queue__section {
  margin-bottom: 2rem;
}

.queue__sectionTitle {
  color: rgba(255, 117, 16, .8);
  text-transform: uppercase;
  letter-spacing: .03rem;
  margin: 0 0 1rem;
  font-size: 1rem;
  line-height: 1.2em;
}

.track {
  -webkit-transform-origin: center;
  transform-origin: center;
  margin-right: -1rem;
  padding: 1rem 0;
  display: flex;
  position: relative;
}

@-webkit-keyframes trackIn {
  from {
    opacity: 0;
    -webkit-transform: scale(.7, 0);
    transform: scale(.7, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes trackIn {
  from {
    opacity: 0;
    -webkit-transform: scale(.7, 0);
    transform: scale(.7, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.track--active:before {
  top: 0;
  content: "";
  background: url("playbar.217978a8.svg") 0 / 1rem 1rem no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: calc(50% - .5rem);
  left: -2.1rem;
}

.track--playing:before {
  background-image: url("playbar-animate.c3433c0f.svg");
}

.track__main {
  cursor: pointer;
  flex-grow: 1;
  min-width: 0;
}

.track__title {
  color: #ff7510;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 1.14em;
  font-size: 14px;
  line-height: 1.14em;
  overflow: hidden;
}

.track--active .track__title {
  font-weight: bold;
}

.track__subtitle {
  color: #ddd;
  min-height: 1.5em;
  margin-top: .2rem;
  font-size: 1.2rem;
  line-height: 1.5em;
}

.track__username {
  text-transform: capitalize;
}

.track .popperMenu {
  flex-shrink: 0;
}

.track__menu {
  color: #ff7510;
  background: none;
  border: none;
  align-items: center;
  margin-left: 1rem;
  padding: 1rem;
  font-size: 0;
  display: flex;
}

.track__menu svg {
  width: 2rem;
}

.contextMenu__overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.contextMenu__overlay.active {
  display: block;
}

.contextMenu {
  z-index: 10;
  visibility: hidden;
  color: #ddd;
  opacity: 0;
  background-color: #bd4f00;
  border-radius: 1rem;
  min-width: 15rem;
  overflow: hidden;
}

.contextMenu.active {
  visibility: visible;
  opacity: 1;
}

.contextMenu__item, .contextMenu__item:link, .contextMenu__item:visited {
  color: #ddd;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 1.2rem 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: block;
}

.contextMenu__item:hover, .contextMenu__item:active, .contextMenu__item:focus {
  background-color: #ff7510;
}

.modal {
  z-index: 20;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal__overlay {
  z-index: 20;
  background: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal__container {
  z-index: 21;
  box-sizing: border-box;
  background-color: #333;
  border-radius: .5rem;
  width: 100%;
  max-width: 52rem;
  max-height: 100vh;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 3rem;
  position: relative;
  overflow-y: auto;
}

.modal__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal__title {
  color: #ff7510;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.6em;
}

.modal__close {
  color: #fff;
  background: none;
  border: 0;
  font-size: 3rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.modal__close:before {
  content: "✕";
}

.modal__btn {
  -webkit-appearance: button;
  color: rgba(0, 0, 0, .8);
  text-transform: none;
  cursor: pointer;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #e6e6e6;
  border-style: none;
  border-width: 0;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.15;
  transition: -webkit-transform .25s ease-out, transform .25s ease-out, -webkit-transform .25s ease-out;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  color: #fff;
  background-color: #00449e;
}

.modalShare__controls {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

.modalShare__input {
  color: #ff7510;
}

.modalShare__bigplayer label {
  margin: 0;
}

.modalShare__copy {
  color: #000;
  background-color: #ff7510;
  border: none;
  border-radius: .3rem;
  margin-left: 1rem;
  padding: .8rem 1.6rem;
  font-size: 1.3rem;
}

.svelte-tags-input-layout {
  background: #444;
  border: .1rem solid #000;
  border-radius: .3rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 2rem .5rem 1.5rem;
  display: flex;
}

.svelte-tags-input-layout:focus-within {
  box-shadow: 0 0 0 .3rem rgba(34, 34, 34, .5);
}

.svelte-tags-input {
  box-shadow: none;
  border: none;
  flex: 1;
  min-width: 0;
  margin: 5px 0 0;
  padding: .35rem .5rem;
  line-height: 1.5em;
}

.svelte-tags-input:focus {
  box-shadow: none;
  outline: 0;
}

.svelte-tags-input-tag {
  color: #fff;
  white-space: nowrap;
  background-color: #ff7510;
  border-radius: .2rem;
  margin-top: .5rem;
  margin-right: .5rem;
  padding: 0 0 0 .9rem;
  font-family: inherit;
  font-size: 1.3rem;
  list-style: none;
  display: flex;
}

.svelte-tags-input-tag-remove {
  cursor: pointer;
  margin-left: .3rem;
  padding: .5rem;
  font-size: 1.6rem;
  line-height: 1em;
}

.svelte-tags-input-tag-remove:hover {
  background-color: rgba(0, 0, 0, .3);
}

.contextEnabled {
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;
}

.context {
  z-index: 50;
  background-color: #333;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.context-head {
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.context-name {
  color: #ff7510;
  margin: 0;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.16em;
}

.context-close {
  background: none;
  border: none;
  border-radius: .3rem;
  margin-left: 1rem;
  margin-right: -.2rem;
  padding: .2rem;
}

.context-close__icon {
  color: rgba(255, 117, 16, .8);
  background-color: #000;
  border-radius: .3rem;
  padding: 1.1rem;
  font-size: 0;
}

.context-close__label {
  color: rgba(255, 117, 16, .8);
  margin-top: .4rem;
  font-size: 1.2rem;
}

.context-content {
  color: #ff7510;
  padding-bottom: 8rem;
  font-size: 1.6rem;
  line-height: 1.55em;
}

.context-content a {
  text-decoration: underline;
}

.context-content .txtbig {
  font-size: 1.8rem;
}

.context-content hr {
  background: #d8d8d8;
  border: none;
  height: .1rem;
  margin: 4rem 0;
  position: relative;
}

.context-content hr:before {
  content: "";
  background: #333 url("separator-tentacle.0efeecc9.svg") center / auto 100% no-repeat;
  width: 3rem;
  height: 2.7rem;
  position: absolute;
  top: -1.3rem;
  left: calc(50% - 1.5rem);
}

.context-content h2 {
  color: #ff7510;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.settings {
  padding-bottom: 4rem;
}

.settings-line {
  min-width: 0;
  margin-bottom: 4rem;
  display: flex;
}

.settings-main {
  flex-grow: 1;
  font-size: 1.4rem;
}

.settings-side {
  flex-shrink: 0;
  width: 16rem;
  margin-right: 2rem;
}

.settings-side__title {
  color: #ff7510;
  margin-bottom: .5rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.55em;
}

.settings-side__subtitle {
  color: rgba(255, 117, 16, .8);
  font-size: 1.3rem;
  line-height: 1.38em;
}

.settings-srv {
  border-radius: 1rem;
  margin: .5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .header {
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }

  .header__side {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 480px) {
  .player {
    flex-direction: column;
    display: flex;
  }

  .playerMini {
    align-self: center;
    width: 16rem;
    margin-bottom: 2rem;
  }

  .track--active:before, .playerSticky .controls__volume, .playerSticky .playerProgress, .playerSticky__referer {
    display: none;
  }

  .playerSticky__cover, .playerSticky .controls {
    margin-right: 1rem;
  }

  .playerSticky .controls__prevnext {
    display: none;
  }

  .playerSticky__infos {
    margin-right: 0;
  }
}

div.app {
  padding: 0 4rem;
  background-color: #222 !important;
}

/*# sourceMappingURL=index.e94a9b89.css.map */
